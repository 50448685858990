import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)},
  {
    path: 'kurse',
    loadChildren: () => import('./pages/kurse/kurse.module').then( m => m.KursePageModule)
  },
  {
    path: 'team',
    loadChildren: () => import('./pages/team/team.module').then( m => m.TeamPageModule)
  },
  {
    path: 'swem',
    loadChildren: () => import('./pages/swem/swem.module').then( m => m.SWEMPageModule)
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./pages/kontakt/kontakt.module').then( m => m.AnreisePageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin-pages/admin/admin.module').then( m => m.AdminPageModule)
  },
  {
    path: 'impressum',
    loadChildren: () => import('./pages/impressum/impressum/impressum.module').then( m => m.ImpressumPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
