import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {


  statu$ = this._http.get<{status: boolean}>('/api/document/schutzkonzept/status').pipe(tap(r => this.status=r.status)).subscribe();
  status: boolean = false;
  teillink = '/api/document/teilnahmebedingungen';
  schutzlink = '/api/document/schutzkonzept';
  constructor(private _http: HttpClient,) { 
  }

  ngOnInit() {}

}
