import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, map, take } from 'rxjs/operators';
import { AdminInfo } from '../interfaces/admin-info';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminService implements CanActivate {

  constructor(private _http: HttpClient) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.isLoggedIn();
  }

  public login(userName: string, password: string): Observable<AdminInfo> {
    return this._http.post<AdminInfo>('/api/admin/login', { userName, password }).pipe(
      tap(a => localStorage.setItem('token', a.token)),
      tap(a => localStorage.setItem('expiresAt', JSON.parse(atob(a.token.split('.')[1])).exp))
    );
  }

  public logout() {
    localStorage.setItem('token', null)
  }

  public isLoggedIn():boolean{
    return Number.parseInt(localStorage.getItem('expiresAt')) > Date.now().valueOf()/1000
  }
}

