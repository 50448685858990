import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

  public current: string;

  constructor(private _adminService: AdminService) {  }

  ngOnInit() {}

  showAdminTab(): boolean{
    return this._adminService.isLoggedIn();
  }
  
}
